<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-01-23 09:41:02
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-01-23 18:32:52
 * @FilePath: /yiyun_project/src/views/shortVideo/shortVideoCate.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-main>
    <div class="btnBox">
      <el-button size="mini" @click="addCate(0)" type="primary">+ 添加分类</el-button>
    </div>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="分类名称：">
        <el-input size="small" v-model="dataInfo.name"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="getAllCate">搜索</el-button>
        <el-button type="text" size="small" @click="clearsearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataInfo.list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column type="expand">
        <template v-slot="{ row }">
          <div style="text-align: center" v-if="!row._child">暂无二级分类</div>
          <div v-else>
            <div class="second_box" v-for="second in row._child" :key="second.id">
              <div style="width: 80px" class="sort">{{ second.sort }}</div>
              <div style="width: 150px" class="catename">{{ second.catename }}</div>
              <div style="width: 80px" class="cateimg"><el-image :preview-src-list="[second.cateimg]" :src="second.cateimg" style="width: 50px; height: 50px"></el-image></div>
              <div class="cate_desc" style="width: 150px">{{ second.cate_desc }}</div>
              <div class="sort" style="width: 150px">{{ getDateformat(second.create_time) }}</div>
              <div class="sort" style="width: 80px"><el-switch v-model="second.is_show" disabled :active-value="1" :inactive-value="0"></el-switch></div>
              <div class="sort">
                <el-button type="text" @click="updateCate(second)">编辑</el-button>
                <el-button type="text" @click="deleteCate(second)">删除</el-button>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="sort" width="80px" label="排序" align="center"></el-table-column>
      <el-table-column prop="catename" width="150px" label="分类名称" align="center"></el-table-column>
      <el-table-column label="分类封面" width="80px" align="center">
        <template v-slot="{ row }">
          <el-image :preview-src-list="[row.cateimg]" :src="row.cateimg" style="width: 50px; height: 50px"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="cate_desc" width="150px" label="分类详情" align="center"></el-table-column>
      <el-table-column label="创建时间" width="150px" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column label="是否显示" width="80px" align="center">
        <template v-slot="{ row }">
          <el-switch v-model="row.is_show" disabled :active-value="1" :inactive-value="0"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" @click="addCate(row)">添加子分类</el-button>
          <el-button type="text" @click="updateCate(row)">编辑</el-button>
          <el-button type="text" @click="deleteCate(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- <Paging :total="dataInfo.total" :page="dataInfo.page" :pageNum="dataInfo.rows" @updatePageNum="updateData"></Paging> -->
    <!-- 添加/编辑分类弹窗 -->
    <el-dialog :title="form_info.id ? '编辑分类' : '添加分类'" :visible.sync="show_form_dialog">
      <el-form :model="form_info" label-width="100px">
        <el-form-item label="排序">
          <el-input v-model="form_info.sort" type="number"></el-input>
        </el-form-item>
        <el-form-item label="上级分类">
          <el-select v-model="form_info.parent_id" placeholder="请选择">
            <el-option v-for="item in cate_all_list" :key="item.id" :label="item.catename" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类名称">
          <el-input v-model="form_info.catename"></el-input>
        </el-form-item>
        <el-form-item label="分类封面">
          <ReadyUploadSource :path="form_info.cateimg" @getSource="val => (form_info.cateimg = val.path)" @removeThis="form_info.cateimg = ''"></ReadyUploadSource>
        </el-form-item>
        <el-form-item label="分类详情">
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model="form_info.cate_desc"></el-input>
        </el-form-item>
        <el-form-item label="是否显示">
          <el-switch v-model="form_info.is_show" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show_form_dialog = false">取 消</el-button>
        <el-button type="primary" @click="sureUpdate">确 定</el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
import ReadyUploadSource from '@/components/readyUploadSource';
import _ from 'lodash';
export default {
  components: {
    // Paging,
    ReadyUploadSource,
  },
  data() {
    return {
      getDateformat,
      dataInfo: {
        page: 1,
        rows: 10,
        total: 0,
        list: [],
        name: '',
      },
      show_form_dialog: false,
      form_info: {
        sort: '',
        catename: '',
        cateimg: '',
        cate_desc: '',
        is_show: 1,
        parent_id: 0,
      },
      cate_all_list: [],
    };
  },
  created() {
    // this.getDataList();
    this.getAllCate(1);
  },
  methods: {
    getAllCate(style) {
      let obj = {};
      if (this.dataInfo.name) obj.name = this.dataInfo.name;
      this.$axios.post(this.$api.shortVideo.all_cate_list, obj).then(res => {
        if (res.code == 0) {
          this.dataInfo.list = res.result;
          if (style) this.cate_all_list = res.result;
        }
      });
    },
    deleteCate(data) {
      this.$confirm(`此操作将删除分类:${data.catename}, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.shortVideo.cate_delete, { id: data.id });
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('删除成功');
            this.getDataList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    addCate(data) {
      this.form_info = {
        sort: '',
        catename: '',
        cateimg: '',
        cate_desc: '',
        is_show: 1,
        parent_id: 0,
      };
      if (data) this.form_info.parent_id = data.id;
      this.show_form_dialog = true;
    },
    updateCate(data) {
      for (const key in this.form_info) {
        this.form_info[key] = data[key];
      }
      this.form_info.id = data.id;
      this.show_form_dialog = true;
    },
    updateData(val, status) {
      if (status == 0) {
        this.dataInfo.rows = val;
      } else {
        this.dataInfo.page = val;
      }
      this.getDataList();
    },
    clearsearch() {
      this.dataInfo.name = '';
      this.getDataList(1);
    },
    getDataList(style) {
      if (style) this.dataInfo.page = 1;
      let obj = {
        page: this.dataInfo.page,
        rows: this.dataInfo.rows,
      };
      if (this.dataInfo.name) obj.name = this.dataInfo.name;
      this.$axios.post(this.$api.shortVideo.cate_list, obj).then(res => {
        if (res.code == 0) {
          this.dataInfo.list = res.result.list;
          this.dataInfo.total = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    sureUpdate() {
      let obj = _.cloneDeep(this.form_info);
      let url = this.$api.shortVideo.cate_add;
      if (obj.id) url = this.$api.shortVideo.cate_edit;
      this.$axios.post(url, obj).then(res => {
        if (res.code == 0) {
          let str = '添加成功';
          if (obj.id) str = '编辑成功';
          this.$message.success(str);
          this.getDataList();
          this.show_form_dialog = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  .btnBox {
    margin-bottom: 16px;
  }
  .second_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
    & > div {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & > div:last-child {
      flex: 1;
    }
  }
}
</style>
